<template>
  <cui-acl  :roles="['talkmoreB2Csell', 'talkmoreB2Cedit']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Talkmore Privat Salgsoversikt</span>
        <a-button class="col-md-4"  style="float: right;"  @click="downloadData()" v-if="['talkmoreB2Cedit'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            Last ned informasjon
          </a-button>
      </h5>
      <br>
      <div class="mb-3">
        <div class="row">
          <a-input placeholder="Søk tlf nummer" @change="searchNumber" class="col-md-4"/>
          <div class="col-md-4"></div>
          <a-range-picker @change="onChange" :value="dateRange" class="col-md-4"/>
        </div>
      </div>
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="data"
          :expanded-row-keys.sync="expandedRowKeys"
          :pagination="{ pageSize: 50 }"
          >
          <a slot="number" slot-scope="text, record" @click="editSale(record.id)" v-if="['talkmoreB2Cedit'].some(r => $store.state.user.roles.indexOf(r) >= 0) && record.id">{{ text }}</a>
          <a slot="number" slot-scope="text" v-else>{{ text }}</a>
        </a-table>
      </div>
    </div>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { firestore } from '@/services/firebase'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'
import moment from 'moment'

const statuses = []
// const statuses = [
//   {
//     value: '01 registered',
//     text: '01 Registered',
//   },
//   {
//     value: '02 port error B2C',
//     text: '02 Port error Privat',
//   },
//   {
//     value: '03 port error B2B',
//     text: '03 Port error Bedirft',
//   },
//   {
//     value: '10 already active in database',
//     text: '10 Already active in database',
//   },
//   {
//     value: '11 cancelled by customer',
//     text: '11 Cancelled by customer',
//   },
//   {
//     value: '12 Not uploaded',
//     text: '12 Not uploaded',
//   },
// ]

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      data: [],
      oldData: [],
      products: [],
      statuses,
      extraproducts: [],
      expandedRowKeys: [],
      sellers: [],
      columns: [
        {
          title: 'Salgsdato',
          dataIndex: 'date',
          key: 'date',
          width: 150,
        },
        {
          title: 'Nummer',
          dataIndex: 'number',
          key: 'number',
          scopedSlots: { customRender: 'number' },
        },
        {
          title: 'Produkt',
          dataIndex: 'product',
          key: 'product',
        },
        {
          title: 'Mersalg',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Poeng',
          dataIndex: 'points',
          key: 'points',
        },
        {
          title: 'Kommentar',
          dataIndex: 'comment',
          key: 'comment',
          width: 200,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          filters: statuses,
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
      ],
      dateRange: [
        moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'YYYY/MM/DD'),
        moment(new Date(), 'YYYY/MM/DD'),
      ],
    }
  },
  methods: {
    moment,
    searchNumber(e) {
      const number = e.target.value
      const data = this.oldData
      this.data = data.filter(el => {
        let state = el.number.toString().includes(number.toString())
        if (!state && el.children) {
          el.children.forEach(child => {
            if (child.number) {
              state = child.number.toString().includes(number.toString())
            }
          })
        }
        return state
      })
    },
    editSale(id) {
      this.$router.push({ path: `/talkmoreB2C/sales/${id}` })
    },
    onChange(date, dateString) {
      if (date.length) {
        this.dateRange = date
        this.getData()
      }
    },
    aboutUser(uid) {
      this.$router.push({ path: `/hr/persons/${uid}` })
    },
    async downloadData() {
      const oldData = this.data
      const newData = []
      await oldData.forEach(sale => {
        newData.push({
          id: sale.id,
          number: sale.number,
          product: sale.product,
          date: sale.date,
          seller: sale.seller,
          owner: 1,
          points: sale.points,
          status: sale.status,
          campaign: sale.campaign,
          comment: sale.comment,
          ...sale.extras,
        })
        if (sale.children) {
          sale.children.forEach(obj => {
            newData.push({
              id: sale.id,
              number: obj.number,
              product: obj.product,
              date: sale.date,
              seller: sale.seller,
              owner: 0,
              status: sale.status,
              campaign: sale.campaign,
              ...obj.extrasChild,
            })
          })
        }
      })
      console.log(newData)
      this.download(newData)
    },
    download (data) {
      const fileName = 'salesData'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    async getData() {
      this.data = []
      const start = new Date(this.dateRange[0])
      const end = new Date(this.dateRange[1])
      let ref
      if (!['talkmoreB2Cedit'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        console.log(this.user.employeeID)
        // ref.where(firestore.FieldPath('seller', 'id'), '==', this.user.employeeID)
        ref = firestore.collection('tmb2c-sales').where('seller.id', '==', this.user.employeeID)
      } else {
        ref = firestore.collection('tmb2c-sales')
      }
      ref
        // .where('seller.id', '==', 3)
        .where('dateCreated', '>', start)
        .where('dateCreated', '<', end)
        .orderBy('dateCreated', 'asc').get()
        .then(snapshot => {
          let i = 0
          snapshot.forEach(doc => {
            const product = this.products.find(item => item.value === doc.data().product).text
            const children = doc.data().children
            if (children) {
              children.forEach((obj, index) => {
                i += 1
                let childproduct
                if (!doc.data().family) {
                  childproduct = this.products.find(item => item.value === obj.product).text
                } else {
                  childproduct = this.products.find(item => item.value === doc.data().product).text
                }
                let childDesc = ''
                const extrasChild = {}
                if (obj.extra) {
                  obj.extra.forEach(obj => {
                    const extraName = this.extraproducts.find(el => el.value === obj)
                    extrasChild[extraName.text] = 1
                    childDesc = childDesc.concat(extraName.text, ' - ')
                  })
                }
                children[index] = {
                  number: obj.number,
                  product: childproduct,
                  key: i,
                  description: childDesc,
                  extrasChild,
                }
              })
            }
            let description = ''
            const extras = {}
            if (doc.data().extra) {
              doc.data().extra.forEach(obj => {
                const extraName = this.extraproducts.find(el => el.value === obj)
                extras[extraName.text] = 1
                description = description.concat(extraName.text, ' - ')
              })
            }
            i += 1
            this.data.push({
              key: i,
              id: doc.id,
              number: String(doc.data().number),
              product: product,
              points: doc.data().points,
              seller: doc.data().seller.name,
              date: moment(doc.data().dateCreated.toDate()).format('YYYY-MM-DD'),
              description,
              children,
              comment: doc.data().comment,
              status: doc.data().status,
              campaign: doc.data().campaign,
              extras,
            })
          })
          const sellers = [...new Set(this.data.map(item => item.seller))]
          sellers.sort()
          sellers.forEach(seller => {
            this.sellers.push({
              text: seller,
              value: seller,
            })
          })
          const statuses = [...new Set(this.data.map(item => item.status))]
          statuses.sort()
          statuses.forEach(status => {
            this.statuses.push({
              text: status,
              value: status,
            })
          })
        })
      this.oldData = this.data
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    if (['talkmoreB2Cedit'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      this.columns.push({
        title: 'Selger',
        dataIndex: 'seller',
        key: 'seller',
        filters: this.sellers,
        onFilter: (value, record) => record.seller.indexOf(value) === 0,
      })
    }
    firestore.collection('tmb2c-products').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          if (doc.data().type === 'main') {
            this.products.push({
              value: String(doc.id),
              text: String(doc.data().name),
              key: String(doc.id),
              family: Boolean(doc.data().family),
              points: Number(doc.data().points),
            })
          } else if (doc.data().type === 'extra') {
            this.extraproducts.push({
              value: String(doc.id),
              text: String(doc.data().name),
              key: String(doc.id),
              family: Boolean(doc.data().family),
              points: Number(doc.data().points),
            })
          }
        })
        this.getData()
      })
  },
}
</script>
